* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html {
  scroll-behavior: smooth;
}

.ant-btn-primary {
  color: black !important;
  border-color: #006fb1 !important;
}
.ant-btn {
  background: #006fb1 !important;
  color: black !important;
}
.ant-popover-message-title {
  font-size: 16px !important;
}

.ant-drawer-header {
  display: none !important;
}
@font-face {
  font-family: "montserratregular";
  src: url("../src/Fonts/montserrat-regular-webfont.woff2") format("woff2"),
    url("../src/Fonts/montserrat-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "open_sansitalic";
  src: url("../src/Fonts/opensans-italic-variablefont_wdthwght-webfont.woff2")
      format("woff2"),
    url("../src/Fonts/opensans-italic-variablefont_wdthwght-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "paytone_oneregular";
  src: url("../src/Fonts/paytoneone-regular-webfont.woff2") format("woff2"),
    url("../src/Fonts/paytoneone-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "pt_serifbold";
  src: url("../src/Fonts/ptserif-bold-webfont.woff2") format("woff2"),
    url("../src/Fonts/ptserif-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "dm_sansregular";
  src: url("../src/Fonts/dmsans-regular-webfont.woff2") format("woff2"),
    url("../src/Fonts/dmsans-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "racing_sans_oneregular";
  src: url("../src/Fonts/racingsansone-regular-webfont.woff2") format("woff2"),
    url("../src/Fonts/racingsansone-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'dosisextralight';
  src: url("../src/Fonts/dosis-variablefont_wght-webfont.woff2") format("woff2"),
    url("../src/Fonts/dosis-variablefont_wght-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

/* loder css ...................................................................................................................................... */
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin-left: 45vw;
  margin-top: 30vh;
  margin-bottom: 30vh;
}

.ant-popover-buttons button {
  margin-left: 8px;
  height: 31px !important;
  border-radius: 6px !important;
  border-color: none !important;
  border: none !important;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #006fb1;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* modal style */
.ant-btn > span {
  display: inline-block;
  color: white !important;
}

/* chatbot */
.react-chatbot-kit-chat-message-container {

  overflow-x: inherit !important;
  overflow-y: scroll !important;
}