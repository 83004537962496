.options-container {
  display: flex;
  flex-wrap: wrap;
}

.option-button {
  margin: 5px;
  border-radius: 8px;
  padding: 8px;
  color: white;
  background: #7878e8;
  text-align: center;
  border:none;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}
